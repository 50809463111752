import { notification } from "antd";
import { useState, useEffect } from "react";
import { translate } from "../i18n";

type NotificationType = "success" | "info" | "warning" | "error";

const openNotification = (type: NotificationType) => {
	notification[type]({
		placement: "bottom",
		message: translate("serverNetworkProblems.connectionProblem"),
		description: translate("serverNetworkProblems.unstableConnection"),
		duration: 6,
	});
};

export function usePing(url = "https://google.com") {
	const [latency, setLatency] = useState<number | null>(null);
	const [unstable, setUnstable] = useState(false);

	useEffect(() => {
		const interval = setInterval(async () => {
			const start = Date.now();
			try {
				await fetch(url, {
					method: "HEAD",
					cache: "no-store",
					mode: "no-cors",
				});
				const ping = Date.now() - start;
				setLatency(ping);
				setUnstable(ping > 240); // Example: Mark as unstable if latency > 200ms
			} catch {
				setLatency(null);
				setUnstable(true); // Consider timeout or failure as unstable
			}
		}, 10000); // Check every 5 seconds

		return () => clearInterval(interval);
	}, [url]);

	useEffect(() => {
		if (unstable) {
			// openNotification("warning");
		}
	}, [unstable]);

	return { latency, unstable };
}
