import { useEffect, useState } from "react";
import { getUserCountry } from "../helper/user-location.utility";
import { currentCountryVar } from "../App";
import { notification } from "antd";
import usePrevious from "../helper/hooks/usePrevious";
import { translate } from "../i18n";

type NotificationType = "success" | "info" | "warning" | "error";

const openNotification = (
	type: NotificationType,
	notifType: "backOnline" | "offline" = "offline"
) => {
	const message =
		notifType === "backOnline"
			? translate("serverNetworkProblems.online")
			: translate("serverNetworkProblems.offline");

	const description =
		notifType === "backOnline"
			? translate("serverNetworkProblems.onlineMessage")
			: translate("serverNetworkProblems.offlineProblem");

	notification[type]({
		placement: "bottom",
		message: message,
		description: description,
		duration: 6,
	});
};

const networkConnection = (navigator as any)?.connection;

export function useNetworkStatus(isRTL?: boolean) {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	useEffect(() => {
		async function fetchLocation() {
			try {
				const country = await getUserCountry();

				if (country) {
					currentCountryVar(country.name);
				}
			} catch (err) {
				console.error(err);
			}
		}

		if (isOnline) {
			fetchLocation();
		}

		const updateNetworkInfo = () => {
			if (networkConnection) {
				fetchLocation();
			}
		};

		if (networkConnection) {
			networkConnection.addEventListener("change", updateNetworkInfo);
		}

		return () => {
			if (networkConnection) {
				networkConnection.removeEventListener(
					"change",
					updateNetworkInfo
				);
			}
		};
	}, [isOnline]);

	useEffect(() => {
		if (!isOnline) {
			// openNotification("error");
		}
	}, [isOnline]);

	const prev = usePrevious(isOnline);

	const backToOnline = prev === false && isOnline;

	useEffect(() => {
		if (backToOnline) {
			// openNotification("success", "backOnline");
		}
	}, [backToOnline]);

	return {
		isOnline,
	};
}
