import axios from "axios";

export async function uploadPartsInQueue(
	file: File,
	partUrls: { signedUrl: string; partNumber: number }[],
	onProgress?: (uploadedBytes: number, totalBytes: number) => void,
	abortController?: AbortController
) {
	const partSize = 5 * 1024 * 1024; // 5 MB
	const totalBytes = file.size; // Total file size
	let uploadedBytes = 0; // Tracks cumulative uploaded bytes

	const uploadParts: {
		ETag: string;
		PartNumber: number;
	}[] = [];

	let index = 0;
	for (const { signedUrl, partNumber } of partUrls) {
		const start = index * partSize;
		const end = Math.min(start + partSize, file.size);

		const partData = file.slice(start, end);

		// Retry logic parameters
		const maxRetries = 5;
		let attempts = 0;
		let success = false;
		let res: {
			ETag: string;
			PartNumber: number;
		} | null = null;

		while (attempts < maxRetries && !success) {
			try {
				const response = await axios.put(signedUrl, partData, {
					headers: {
						"Content-Type": "application/octet-stream", // Adjust if needed
					},
					signal: abortController?.signal, // Abort signal
					onUploadProgress: (progressEvent) => {
						if (onProgress) {
							onProgress(
								uploadedBytes + progressEvent.loaded,
								totalBytes
							); // Trigger progress callback
						}

						if (
							progressEvent.loaded > 0 &&
							progressEvent.loaded === progressEvent.total
						) {
							uploadedBytes += progressEvent.loaded;
						}
					},
				});

				if (response.status >= 200 && response.status < 300) {
					success = true; // Mark as success
					res = {
						ETag:
							response.headers["etag"]?.replaceAll('"', "") || "",
						PartNumber: partNumber,
					};
				} else {
					throw new Error(`Failed to upload part ${partNumber}`);
				}
			} catch (error) {
				attempts++;
				console.error(`Error uploading part ${partNumber}:`, error);

				if (attempts < maxRetries) {
					console.log(
						`Retrying part ${partNumber}, attempt ${attempts + 1}`
					);
					// Delay between retries (optional, you can adjust it)
					await new Promise((resolve) => setTimeout(resolve, 1000));
				} else {
					throw new Error(
						`Failed to upload part ${partNumber} after ${maxRetries} attempts`
					);
				}
			}
		}

		if (res) {
			uploadParts.push(res);
		}

		index++;
	}

	return uploadParts;
}
