import { notification } from "antd";
import { useEffect, useState } from "react";
import { translate } from "../i18n";

type NotificationType = "success" | "info" | "warning" | "error";

const openNotification = (type: NotificationType) => {
	notification[type]({
		placement: "bottom",
		message: translate("serverNetworkProblems.connectionProblem"),
		description: translate("serverNetworkProblems.unstableConnection"),
		duration: 6,
	});
};

export function useMonitorPacketLoss() {
	const [packetLoss, setPacketLoss] = useState<number | null>(null);
	const [isUnstable, setIsUnstable] = useState(false);

	useEffect(() => {
		const peerConnection = new RTCPeerConnection();

		// Create a dummy data channel to establish the connection
		const dataChannel = peerConnection.createDataChannel("monitor");
		dataChannel.onopen = () =>
			console.log("DataChannel opened for monitoring.");

		// Handle ICE connection state changes
		peerConnection.oniceconnectionstatechange = () => {
			if (peerConnection.iceConnectionState === "disconnected") {
				console.log("Connection unstable: ICE connection lost");
				setIsUnstable(true);
			} else if (peerConnection.iceConnectionState === "connected") {
				setIsUnstable(false);
			}
		};

		// Monitor stats for packet loss every 5 seconds
		const intervalId = setInterval(() => {
			peerConnection.getStats().then((stats) => {
				let packetsLost = 0;
				stats.forEach((report) => {
					if (
						report.type === "outbound-rtp" &&
						report.packetsLost !== undefined
					) {
						packetsLost += report.packetsLost;
					}
				});

				setPacketLoss(packetsLost);
				if (packetsLost > 0) {
					console.log("Connection unstable: Packet loss detected");
					setIsUnstable(true);
				} else {
					setIsUnstable(false);
				}
			});
		}, 5000);

		// Clean up resources on unmount
		return () => {
			clearInterval(intervalId);
			dataChannel.close();
			peerConnection.close();
		};
	}, []);

	useEffect(() => {
		if (isUnstable) {
			// openNotification("warning");
		}
	}, [isUnstable]);

	return {
		isUnstable,
		packetLoss,
	};
}
